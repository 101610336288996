import { teams } from "@src/data/task";
const adminRoutes = [
  {
    label: "Admin",
    children: [
      {
        label: "Dashboard",
        to: {
          path: "/admin/dashboard"
        }
      },
      {
        label: "Tasks",
        to: {
          path: "/admin/tasks"
        },
        children: [
          {
            label: "My active",
            to: {
              path: "/admin/tasks/my"
            }
          },
          {
            label: "All active",
            to: {
              path: "/admin/tasks/all"
            }
          },
          {
            label: "Unassigned",
            to: {
              path: "/admin/tasks/unassigned"
            }
          },
          {
            label: "Attention required",
            to: { path: "/admin/tasks/attention-required" }
          },
          {
            label: "Malware & Security",
            to: {
              path: "/admin/tasks/malware-and-security"
            }
          },
          {
            label: "Scheduled",
            to: {
              path: "/admin/tasks/scheduled"
            }
          },
          {
            label: "Queued",
            to: {
              path: "/admin/tasks/queued"
            }
          },
          {
            label: "Waiting for reply",
            to: {
              path: "/admin/tasks/waiting-for-reply"
            }
          },
          {
            label: "Closed",
            to: {
              path: "/admin/tasks/all-closed"
            }
          }
        ]
      },
      {
        label: "Teams",
        to: {
          path: "/admin/teams"
        },
        children: [
          {
            label: `${teams.MAINTENANCE.label}`,
            to: {
              path: `/admin/teams/${teams.MAINTENANCE.key}`
            }
          }
        ]
      },
      {
        label: "Sites",
        to: {
          path: "/admin/sites"
        },
        children: [
          {
            label: "All sites",
            to: {
              path: "/admin/sites/all"
            }
          },
          {
            label: "Subscribed sites",
            to: {
              path: "/admin/sites/subscribed"
            }
          },
          {
            label: "My sites",
            to: {
              path: "/admin/sites/my"
            }
          }
        ]
      },
      {
        label: "Users",
        to: {
          path: "/admin/users"
        }
      },
      {
        label: "Invoices",
        to: {
          path: "/admin/invoices"
        }
      },
      {
        label: "Orders",
        to: {
          path: "/admin/orders"
        }
      },
      {
        label: "Contracts",
        to: {
          path: "/admin/contracts"
        }
      },
      {
        label: "Reports",
        to: {
          path: "/admin/reports"
        }
      },
      {
        label: "FixedVault™",
        to: {
          path: "/admin/vault"
        }
      },
      {
        label: "System logs",
        to: {
          path: "/admin/logs"
        }
      }
    ]
  }
];

const agentRoutes = [
  {
    label: "Agent",
    children: [
      {
        label: "Tasks",
        to: {
          path: "/admin/tasks/my"
        }
      },
      {
        label: "Sites",
        to: {
          path: "/admin/sites/my"
        }
      },
      {
        label: "Logs",
        to: {
          path: "/admin/logs"
        }
      }
    ]
  }
];

export { agentRoutes, adminRoutes };
