<template>
  <div
    id="admin-container"
    :class="{ 'has-menu-visible': $store.state.ui.adminMenu }"
  >
    <div class="admin-controls">
      <div>
        <button
          class="button is-black has-margin-left-100"
          @click="$store.commit('ui/adminMenu')"
        >
          <b-icon :icon="$store.state.ui.adminMenu ? 'times' : 'bars'" />
        </button>
      </div>

      <div>
        <router-link :to="{ name: 'admin' }">
          <img
            :style="{ height: `36px`, width: `auto` }"
            src="/assets/images/interface/logos/fixed-reverse.svg"
            class="is-block"
          />
        </router-link>
      </div>

      <div>
        <router-link
          :to="{ name: 'sites' }"
          class="button is-black has-margin-right-100"
        >
          <b-icon icon="external-link-square-alt" />
        </router-link>
      </div>
    </div>

    <div class="admin-menu">
      <div class="admin-menu-controls">
        <router-link :to="{ name: 'admin' }">
          <img
            :style="{ height: `36px`, width: `auto` }"
            src="/assets/images/interface/logos/fixed-reverse.svg"
            class="is-block"
          />
        </router-link>
      </div>

      <div class="admin-menu-view">
        <a
          class=" verify-client-button is-spaced-apart"
          @click="openVerifyClientModal"
        >
          <span class="is-size-7">Verify phone client</span>
          <b-icon icon="phone" size="is-small" />
        </a>

        <admin-menu :routes="filteredRoutes" />
      </div>

      <div class="admin-menu-footer">
        <router-link :to="{ name: 'sites' }" class="button is-black is-medium">
          <span class="is-size-6">Go to client app</span>
          <b-icon icon="angle-right" size="is-small" />
        </router-link>
      </div>
    </div>

    <div v-if="hasContent" class="admin-content">
      <div class="admin-content-controls">
        <a
          class="button is-grey is-outlined is-rounded"
          @click="$router.go(-1)"
        >
          <b-icon icon="angle-left" size="is-small" />
          <span>Back</span>
        </a>

        <a
          v-if="hasAside && !$store.state.ui.adminAside && !is1680"
          class="button is-white"
          @click="$store.commit('ui/adminAside')"
        >
          <b-icon icon="info-circle" class="has-text-info" />
        </a>
      </div>

      <div class="admin-content-view">
        <router-view :key="`content--${$route.path}`" name="content" />
      </div>
    </div>

    <div v-else-if="noContent" class="admin-content is-empty">
      <div class="admin-content-controls" />

      <div class="is-flex">
        <component :is="noContent" style="margin: auto;" />
      </div>
    </div>

    <div v-if="hasSubMenu && !omitSubMenu" class="admin-list">
      <router-view name="subMenu" />
    </div>

    <admin-aside>
      <router-view :key="`aside--${$route.path}`" name="aside" />
    </admin-aside>
  </div>
</template>

<script>
import { adminRoutes, agentRoutes } from "./helpers/routes";

export default {
  name: "Admin",
  components: {
    "admin-menu": () => import("@shared/admin/_adminMenu"),
    "admin-aside": () => import("@shared/admin/_adminAside")
  },
  data() {
    return {};
  },
  computed: {
    routes() {
      return this.isUserOnlyAgent ? agentRoutes : adminRoutes;
    },
    filteredRoutes() {
      const func = routes => {
        return this.$_.filter(routes, route => {
          if (this.$_.has(route, "guard")) {
            return route.guard();
          }
          if (route.children) {
            route.children = func(route.children);
          }
          return true;
        });
      };
      return func(this.routes);
    },
    user() {
      return this.$store.getters["user/user"]();
    },
    isUserOnlyAgent() {
      return this.$store.getters["user/isOnlyAgent"]();
    },
    hasSubMenu() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      return this.$_.has(matchedRoute.components, "subMenu");
    },
    omitSubMenu() {
      const matchedRouteMeta = this.$route.matched[
        this.$route.matched.length - 1
      ].meta;
      return matchedRouteMeta.omitSubMenu
        ? matchedRouteMeta.omitSubMenu()
        : false;
    },
    hasContent() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      return this.$_.has(matchedRoute.components, "content");
    },
    hasAside() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      return this.$_.has(matchedRoute.components, "aside");
    },
    showAside() {
      return this.is1680 || this.$store.state.ui.adminAside;
    },
    showMenu() {
      return this.$store.state.ui.adminMenu;
    },
    isMobile() {
      return this.$store.getters["ui/isMobile"]();
    },
    isTablet() {
      return this.$store.getters["ui/isTablet"]();
    },
    is1680() {
      return this.$store.state.ui.window.width >= 1680;
    },
    noContent() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      return matchedRoute.components.noContent || null;
    }
  },
  watch: {
    $route() {
      this.$store.commit("ui/adminAside", false);
    }
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  },
  methods: {
    openVerifyClientModal() {
      this.$modal.open({
        component: () => import("@shared/user/_verifyClientModal"),
        parent: this,
        width: 480,
        hasModalCard: true,
        canCancel: ["escape", "outside"]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

#admin-container {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0;

  @include mobile {
    margin-left: -100vw;
    grid-template-rows: 4rem 1fr;
  }

  @include touch {
  }

  @include desktop {
    grid-template-columns: auto auto 1fr;
  }

  &.has-menu-visible {
    margin-left: 0;
  }
}

.admin-controls {
  grid-row: 1;
  grid-column: 2 / span 3;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background: $black;

  & > div {
    display: flex;
    align-items: center;
    &:last-child {
      justify-content: flex-end;
    }
  }

  @include tablet {
    display: none;
  }

  .has-menu-visible & {
    // z-index: 100;
    grid-column: 1 / span 3;
  }
}

.admin-menu {
  width: 100vw;
  grid-column: 1;
  position: relative;
  overflow: auto;
  background: $dark;
  display: grid;
  position: relative;

  @include mobile {
    grid-row: 2;
  }

  @include tablet {
    grid-row: 1;
    width: 260px;
    grid-template-rows: 4rem 1fr 5rem;
  }

  .admin-menu-controls {
    grid-row: 1;
    background: $black;
    padding: 0 1rem;
  }

  .admin-menu-view {
    overflow: auto;
  }

  .admin-menu-footer {
    grid-row: 3;
    justify-content: center;
  }

  .admin-menu-controls,
  .admin-menu-footer {
    display: flex;

    align-items: center;
    @include mobile {
      display: none;
    }
  }
}

.admin-list {
  background: $white;

  grid-column: 2;
  position: relative;
  overflow: auto;

  @include mobile {
    grid-row: 2;
  }

  @include tablet {
    grid-row: 1;
    width: 100%;
    border-right: 1px solid $border;
  }
  @include desktop {
    width: 360px;
  }
}

.admin-content {
  display: grid;
  position: relative;
  grid-template-rows: 4rem 1fr;
  overflow: auto;
  background: $light;

  @include mobile {
    grid-row: 2;
    grid-column: 2;
  }

  @include tablet {
    grid-row: 1;
  }

  @include desktop {
    grid-column: 3;
  }

  .admin-content-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    border-bottom: 1px solid $border;
    padding: 0.75em;
  }

  .admin-content-view {
    position: relative;
    overflow: auto;
  }

  &.is-empty,
  &:not(.is-empty) + .admin-list {
    @include touch {
      display: none;
    }
  }
}
.verify-client-button {
  border-radius: 0;
  border-style: solid;
  border-color: rgba($white, 0.5);
  border-width: 1px 0;
  background: $black;
  color: $black-invert;
  width: 100%;
  padding: 0.6125rem 1rem;
  outline: none;
  transition: color ease-out 0.12s;
  &:hover {
    cursor: pointer;
    color: $link;
  }
}
</style>
